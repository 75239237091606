<template>
  <div>
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
      <div class="formgrid grid m-2">
        <div class="field col-12 md:col-5">
          <div class="grid">
            <div class="field col-12 md:col-6">
              <label>Kode</label>
              <InputText
                v-model="form.supp"
                type="text"
                class="w-full"
                input-class="w-full"
                maxlength="5"
              />
            </div>
            <div class="field col-12 md:col-2 mt-5">
              <input
                :checked="form.active"
                type="checkbox"
                @click="OnActiveCheckClick"
              /><label>Aktif</label>
            </div>
            <div class="field col-12 md:col-4 mt-5">
              <input
                :checked="form.secondary"
                type="checkbox"
                @click="OnSecondaryCheckClick"
              /><label>Secondary</label>
            </div>
          </div>
          <div class="grid">
            <div class="field col-12 md:col-12">
              <label>Nama</label>
              <InputText
                v-model="form.name"
                :class="{ 'p-invalid': v$.form.name.$invalid && submitted }"
                type="text"
                class="w-full"
                input-class="w-full"
                maxlength="50"
              />
              <small
                v-if="
                  (v$.form.name.$invalid && submitted) ||
                  v$.form.name.$pending.$response
                "
                class="p-error"
                >{{ v$.form.name.required.$message }}</small
              >
            </div>
          </div>
          <div class="grid">
            <div class="field col-12 md:col-12">
              <label>Alamat</label>
              <InputText
                v-model="form.address"
                type="text"
                class="w-full"
                input-class="w-full"
                maxlength="50"
              />
            </div>
          </div>
          <div class="grid">
            <div class="field col-12 md:col-6">
              <label>Kota</label>
              <InputText
                v-model="form.city"
                type="text"
                class="w-full"
                input-class="w-full"
                maxlength="25"
              />
            </div>
            <div class="field col-12 md:col-6">
              <label>Kodepos</label>
              <InputText
                v-model="form.postal"
                type="text"
                class="w-full"
                input-class="w-full"
                maxlength="7"
              />
            </div>
          </div>
          <div class="grid">
            <div class="field col-12 md:col-6">
              <label>No. Tlp.</label>
              <InputText
                v-model="form.phone"
                type="text"
                class="w-full"
                input-class="w-full"
                maxlength="25"
              />
            </div>
            <div class="field col-12 md:col-6">
              <label>No. Fax</label>
              <InputText
                v-model="form.fax"
                type="text"
                class="w-full"
                input-class="w-full"
                maxlength="25"
              />
            </div>
          </div>
          <hr />
          <br />
          <div class="grid">
            <div class="field col-12 md:col-6">
              <label>Nama PKP</label>
              <InputText
                v-model="form.pkp_name"
                type="text"
                class="w-full"
                input-class="w-full"
                maxlength="50"
              />
            </div>
            <div class="field col-12 md:col-6">
              <label>No. PKP</label>
              <InputText
                v-model="form.pkp_no"
                type="text"
                class="w-full"
                input-class="w-full"
                maxlength="255"
              />
            </div>
          </div>
        </div>
        <div class="field col-12 md:col-7">
          <div class="grid">
            <div class="field col-12 md:col-6">
              <label>Nama Kontak</label>
              <InputText
                v-model="form.cp"
                type="text"
                class="w-full"
                input-class="w-full"
                maxlength="50"
              />
            </div>
            <div class="field col-12 md:col-6">
              <label>No. Kontak</label>
              <InputText
                v-model="form.cp_phone"
                type="text"
                class="w-full"
                input-class="w-full"
                maxlength="25"
              />
            </div>
          </div>

          <div class="grid">
            <div class="field col-12 md:col-6">
              <label>Email</label>
              <InputText
                v-model="form.cp_email"
                type="text"
                class="w-full"
                :class="{ 'p-invalid': v$.form.cp_email.$invalid && submitted }"
                input-class="w-full"
                maxlength="50"
              />
              <small
                v-if="
                  (v$.form.cp_email.$invalid && submitted) ||
                  v$.form.cp_email.$pending.$response
                "
                class="p-error"
                >{{ v$.form.cp_email.email.$message }}</small
              >
            </div>
          </div>
          <hr />
          <br />
          <div class="grid">
            <div class="field col-12 md:col-5">
              <label>Termin</label>
              <Dropdown
                v-model="form.term_id"
                :options="listTerm"
                class="w-full"
                optionValue="id"
                optionLabel="desc"
                :showClear="true"
                filter
              >
                <template #option="slotProps">
                  <b>{{ slotProps.option.code }}</b
                  ><br />
                  {{ slotProps.option.desc }}
                </template>
              </Dropdown>
              <small
                v-if="
                  (v$.form.term_id.$invalid && submitted) ||
                  v$.form.term_id.$pending.$response
                "
                class="p-error"
                >{{ v$.form.term_id.required.$message }}</small
              >
            </div>
            <div class="field col-12 md:col-7">
              <label>Pembayaran</label>
              <div class="col">
                <div class="formgroup-inline">
                  <div class="field-radiobutton">
                    <RadioButton value="tunai" v-model="form.payment" />
                    <label>TUNAI</label>
                  </div>
                  <div class="field-radiobutton">
                    <RadioButton value="giro" v-model="form.payment" />
                    <label>GIRO/CEK</label>
                  </div>
                  <div class="field-radiobutton">
                    <RadioButton value="transfer" v-model="form.payment" />
                    <label>TRANSFER</label>
                  </div>
                </div>
              </div>
              <small
                v-if="
                  (v$.form.payment.$invalid && submitted) ||
                  v$.form.payment.$pending.$response
                "
                class="p-error"
                >{{ v$.form.payment.required.$message }}</small
              >
            </div>
          </div>
          <div
            v-if="form.payment == 'giro' || form.payment == 'transfer'"
            class="grid"
          >
            <div class="field col-12 md:col-6">
              <label>Bank</label>
              <InputText
                v-model="form.bank"
                type="text"
                class="w-full"
                input-class="w-full"
                maxlength="50"
              />
            </div>
            <div class="field col-12 md:col-6">
              <label>Atas Nama</label>
              <InputText
                v-model="form.bank_name"
                type="text"
                class="w-full"
                input-class="w-full"
                maxlength="50"
              />
            </div>
          </div>
          <div
            v-if="form.payment == 'giro' || form.payment == 'transfer'"
            class="grid"
          >
            <div class="field col-12 md:col-12">
              <label>No. Rekening</label>
              <InputText
                v-model="form.bank_acc"
                type="text"
                class="w-full"
                input-class="w-full"
                maxlength="50"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="flex justify-content-end align-items-end">
        <Button
          label="Tutup"
          icon="pi pi-times"
          @click="$emit('close')"
          class="p-button-text mr-2"
        />
        <Button
          :loading="loading"
          label="Simpan"
          icon="pi pi-check"
          type="submit"
        />
      </div>
    </form>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { helpers, required, email } from '@vuelidate/validators'

export default {
  setup: () => ({ v$: useVuelidate() }),
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default() {
        return {}
      },
    },
    listTerm: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      submitted: false,
    }
  },
  computed: {
    form() {
      return this.item
    },
  },
  methods: {
    handleSubmit(isFormValid) {
      this.submitted = true

      if (!isFormValid) {
        return
      }

      this.$emit('save', this.form)
    },
    OnActiveCheckClick() {
      // for casting active checkbox checked value to int
      this.form.active = this.form.active == 0 ? 1 : 0
    },
    OnSecondaryCheckClick() {
      // for casting secondary checkbox checked value to int
      this.form.secondary = this.form.secondary == 0 ? 1 : 0
    },
  },
  validations() {
    return {
      form: {
        name: {
          required: helpers.withMessage('Nama harus diisi.', required),
        },
        // cp: {
        //   required: helpers.withMessage('Nama kontak harus diisi.', required),
        // },
        cp_email: {
          email: helpers.withMessage(
            'Email harus diisi sesuai format email.',
            email
          ),
          required,
        },
        term_id: {
          required: helpers.withMessage('Termin harus diisi.', required),
        },
        payment: {
          required: helpers.withMessage('Harus memilih salah satu jenis pembayaran.', required),
        },
      },
    }
  },
}
</script>
