<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">Supplier</span>
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small">/ Master / Supplier / Data Supplier</span>
      </div>
    </div>
    <div class="card bg-white">
      <div class="flex justify-content-end mb-2">
        <Button
          icon="pi pi-plus"
          label="Supplier"
          class="mr-2"
          @click="onAddData"
        />
      </div>
      <fx-table
        :items="items"
        :loading="isLoading"
        :total="totalRecords"
        :grid-options="options"
        :filters="filters"
        @request="onRequestData"
        @filter="onFilter()"
      >
        <template #columns>
          <Column
            field="supp"
            header="Kode"
            style="min-width: 10rem"
            sortable
            :filter-match-mode-options="equalModesOption"
          >
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                v-model="filterModel.value"
                type="text"
                class="p-column-filter"
                placeholder="Search"
                @keydown.enter="filterCallback()"
              />
            </template>
          </Column>
          <Column
            field="name"
            header="Nama"
            style="min-width: 15rem"
            sortable
            :filter-match-mode-options="containModesOption"
          >
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                v-model="filterModel.value"
                type="text"
                class="p-column-filter"
                placeholder="Search"
                @keydown.enter="filterCallback()"
              />
            </template>
          </Column>
          <Column field="address" header="Alamat" style="min-width: 15rem">
          </Column>
          <Column field="city" header="Kota" style="min-width: 8rem" />
          <Column field="term.desc" header="Termin" style="min-width: 8rem" />
          <Column field="payment" header="Pembayaran" style="min-width: 7rem" />
          <Column header="Aktif" style="min-width: 6rem" header-style="justify-content: center">
            <template #body="slotProps">
              <div style="width: 100%; text-align: center">
                <Tag
                  v-if="slotProps.data.active"
                  severity="success"
                  value="Aktif"
                ></Tag>
                <Tag
                  v-else
                  :style="{ background: 'grey' }"
                  value="Tidak Aktif"
                ></Tag>
              </div>
            </template>
          </Column>
          <Column style="flex-grow: 1; justify-content: flex-end">
            <template #body="{ data }">
              <Button
                type="button"
                icon="pi pi-pencil"
                class="p-button-outlined p-button-secondary p-button-text mr-2"
                @click="onEditData(data)"
              />
              <Button
                type="button"
                icon="pi pi-trash"
                class="p-button-outlined p-button-danger p-button-text"
                @click="onConfirmDeletion(data)"
              />
            </template>
          </Column>
        </template>
      </fx-table>
    </div>
    <Dialog
      :header="form && doprocess > 0 ? 'Edit Supplier' : 'Tambah Supplier'"
      v-model:visible="dialog"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '95vw' }"
      :modal="true"
      :maximizable="true"
    >
      <form-supplier
        :item="form"
        :list-term="list_term"
        :loading="isLoadingSave"
        :supplier="supplier"
        @save="onSaveData"
        @close="dialog = false"
      />
    </Dialog>
    <Dialog
      header="Hapus Supplier"
      v-model:visible="dialogHapus"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      :modal="true"
    >
      <div class="confirmation-content">
        <span
          >Supplier <strong>{{ form.name }}</strong> akan dihapus. Proses?</span
        >
      </div>
      <template #footer>
        <Button
          label="Hapus"
          icon="pi pi-trash"
          @click="onDeleteSupplier"
          class="p-button-text p-button-danger"
        />
      </template>
    </Dialog>
    <hotkey :shortcuts="['D']" @triggered="onTriggerHotkey" />
  </div>
</template>

<script>
import SupplierService from '@/services/SupplierService'
import TermService from '@/services/TermService'
import FormSupplier from '@/components/master/FormSupplier'

import FxTable from '@/components/FxTable'
import errorHandler from '@/helpers/error-handler'
import Hotkey from '@/components/Hotkey'
import { FilterMatchMode } from 'primevue/api'
import Tag from 'primevue/tag'

export default {
  name: 'Supplier',
  components: {
    FormSupplier,
    FxTable,
    Hotkey,
    Tag,
  },
  data() {
    return {
      supplierService: null,
      dialog: false,
      dialogHapus: false,
      isLoading: false,
      list_term: [],
      items: [],
      item: {},
      form: {},
      doprocess: 0,
      suplier: [],
      totalRecords: 0,
      options: {
        page: 1,
        rows: 10,
        sortField: 'supp',
        sortOrder: 'asc',
        filters: {},
      },
      filters: {
        supp: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      containModesOption: [
        { label: 'Contains', value: FilterMatchMode.CONTAINS },
      ],
      equalModesOption: [{ label: 'Equals', value: FilterMatchMode.EQUALS }],
    }
  },
  created() {
    this.supplierService = new SupplierService()
  },
  async mounted() {
    this.loadData()
    const termService = new TermService()
    await termService
      .get()
      .then((res) => {
        this.list_term = res.data
      })
      .catch((err) => {
        errorHandler(err, 'Data Kategori', this)
      })
  },
  methods: {
    onRequestData(e) {
      this.options = e
      this.loadData()
    },
    loadData() {
      this.isLoading = true
      const page = this.options.page
      const perPage = this.options.rows
      const sortField = this.options.sortField
      const sortOrder = this.options.sortOrder
      const filters = this.options.filters

      this.supplierService
        .paginate(page, perPage, sortField, sortOrder, filters)
        .then((res) => {
          this.items = res.data
          this.totalRecords = res.total
          this.isLoading = false
        })
        .catch((err) => {
          errorHandler(err, 'Data Supplier', this)
        })
        .finally(() => (this.isLoading = false))
    },
    onFilter() {
      this.options.value.filters = this.filters.value
      this.loadData()
    },
    onEditData(data) {
      this.form = Object.assign({}, data)
      if (this.form.term_id === 0) {
        this.form.term_id = null
      }
      this.dialog = true
      this.doprocess = 1
    },
    onAddData() {
      this.form.supp = ''
      this.form.name = ''
      this.form.address = ''
      this.form.city = ''
      this.form.postal = ''
      this.form.phone = ''
      this.form.fax = ''
      this.form.active = 1
      this.form.cp = ''
      this.form.cp_phone = ''
      this.form.cp_email = ''
      this.form.secondary = 0
      this.form.pkp_name = ''
      this.form.pkp_no = ''
      this.form.term_id = this.list_term.length > 0 ? this.list_term[0].id : 0
      this.form.payment = 'tunai'
      this.form.bank = ''
      this.form.bank_name = ''
      this.form.bank_acc = ''

      this.dialog = true
      this.doprocess = 0
    },
    onSaveData(data) {
      this.isLoadingSave = true
      const item = {}
      item.supp = data.supp
      item.name = data.name
      item.address = data.address
      item.city = data.city
      item.postal = data.postal
      item.phone = data.phone
      item.fax = data.fax
      item.active = data.active
      item.cp = data.cp
      item.cp_phone = data.cp_phone
      item.cp_email = data.cp_email === '' ? null : data.cp_email
      item.secondary = data.secondary
      item.pkp_name = data.pkp_name
      item.pkp_no = data.pkp_no
      item.term_id = data.term_id == null ? 0 : data.term_id
      item.payment = data.payment
      item.bank = data.bank
      item.bank_name = data.bank_name
      item.bank_acc = data.bank_acc
      this.item = item
      if (data && this.doprocess == 0) {
        // const newData = Object.assign({}, data)
        this.supplierService
          .add(item)
          .then((res) => {
            if (res.data.status === 1) {
              this.loadData()
              this.$toast.add({
                severity: 'success',
                summary: 'Data Supplier',
                detail: 'Data berhasil disimpan.',
                life: 3000,
              })
              this.dialog = false
            }
          })
          .catch((err) => {
            errorHandler(err, 'Data Supplier', this)
          })
          .finally(() => (this.isLoadingSave = false))
      } else if (data && this.doprocess == 1) {
        // const newData = Object.assign({}, data)

        this.supplierService
          .update(data.id, item)
          .then((res) => {
            if (res.data.status === 1) {
              this.loadData()
              this.$toast.add({
                severity: 'success',
                summary: 'Data Supplier',
                detail: 'Data berhasil disimpan.',
                life: 3000,
              })
              this.dialog = false
            }
          })
          .catch((err) => {
            errorHandler(err, 'Data Supplier', this)
          })
          .finally(() => (this.isLoadingSave = false))
      }
    },
    onConfirmDeletion(data) {
      this.form = data
      this.dialogHapus = true
    },
    onDeleteSupplier() {
      this.supplierService
        .delete(this.form.id)
        .then((res) => {
          if (res.data.status === 1) {
            this.$toast.add({
              severity: 'success',
              summary: 'Data Supplier',
              detail: 'Data berhasil dihapus.',
              life: 3000,
            })
            this.loadData()
          }
        })
        .catch((err) => {
          errorHandler(err, 'Data Supplier', this)
        })
      this.form = {}
      this.dialogHapus = false
    },
    onTriggerHotkey(payload) {
      if (payload.keyString === 'D') {
        this.onAddData()
      }
    },
    /* onToggle(value) {
      this.selectedColumns = this.columns.filter((col) => value.includes(col))
    }, */
  },
}
</script>
